<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div v-html="list.articleContent"></div>
        <div class="links">
          <ul>
            <li><a></a></li>
            <li @click="goThere"><a></a></li>
            <li @click="goThere"><a></a></li>
          </ul>
        </div>
        <div class="clears"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      list: [],
    };
  },
  computed: {
    //获取菜单
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    //获取当前的router
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getList() {
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 10,
          pageNo: 1,
        }).then((res) => {
          this.list = res;
        });
      }
    },
    goThere() {
      this.$router.push({
        name: "mcontactUs",
      });
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    $route() {
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .links {
        float: right;
        ul {
          list-style: none;
          li {
            float: left;
            width: 2rem;
            height: 2rem;
            background-size: 100% 100% !important;
            margin-left: 0.3rem;
            &:nth-child(1) {
              background: url(../../../../public/imgs/icon/link01.png) center
                no-repeat;
            }
            &:nth-child(2) {
              background: url(../../../../public/imgs/icon/link02.png) center
                no-repeat;
            }
            &:nth-child(3) {
              background: url(../../../../public/imgs/icon/link03.png) center
                no-repeat;
            }
            a {
              display: block;
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }
      .clears {
        clear: both;
        line-height: 0;
        overflow: hidden;
        font-size: 0;
        height: 0;
      }
    }
  }
}
</style>